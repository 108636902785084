<template>
  <component
    :is="as"
    @click="$emit('clickBtn')"
    class="flex cursor-pointer items-center justify-center gap-4 rounded-[30px] max-md:px-5"
    :class="buttonStyle"
  >
    <img
      v-if="icon"
      :src="require('@/assets/icons/svg/' + icon)"
      :alt="text"
      class="aspect-square w-6 max-w-full shrink-0 overflow-hidden object-contain object-center"
    />
    <div v-show="showText"
      class="self-center font-medium leading-5 h-fit flex justify-center items-center"
      :class="type === 'outlined' ? 'text-mainBlack-1000' : 'text-white'"
    >
      {{ text }}
    </div>
  </component>
</template>

<script setup>
import { computed, defineProps } from 'vue'

const props = defineProps({
  as: {
    type: String,
    default: 'button',
    validator: (value) => ['router-link', 'button', 'a'].includes(value)
  },
  type: {
    type: String,
    default: 'mainBlack',
    validator: (value) => ['mainBlack', 'primary', 'outlined', 'secondary'].includes(value)
  },
  icon: {
    type: String
  },
  showText: {
    type: Boolean,
    default: true
  },
  classList: {
    type: [String],
    default: ''
  },
  paddingStyle: {
    type: [String],
    default: 'px-9 py-[1.125rem]'
  },
  text: {
    type: String,
    default: 'button'
  }
})

const buttonStyle = computed(() => {
  switch (props.type) {
    case 'mainBlack':
      return 'bg-mainBlack-1000 ' + props.classList + ' ' + props.paddingStyle
    case 'primary':
      return 'bg-primary-1000 ' + props.classList + ' ' + props.paddingStyle
    case 'secondary': 
      return 'bg-secondary-1000 ' + props.classList + ' ' + props.paddingStyle
    case 'outlined':
      return (
        'bg-white border border-black shadow-[0px_0px_0px_2px_rgba(0,0,0,0.2)] ' +
        props.classList +
        ' ' +
        props.paddingStyle
      )
    default:
      return 'bg-mainBlack-1000 ' + props.classList + ' ' + props.paddingStyle
  }
})
</script>
