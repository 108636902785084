<template>
  <div class="qooBox-main">
    <MainHeader v-if="$route.meta.layout == 'default'" />
    <router-view/>
  </div>
  <OverlayLoader v-show="isLoading"/>
</template>

<script setup>
import OverlayLoader from '@/components/base/overlay/OverlayLoader.vue'
import MainHeader from '@/components/base/MainHeader'
import { computed, onMounted } from 'vue'
import { useStore } from '@/store/loading.js'

const store = useStore()

const isLoading = computed(() => {
  return store.isLoading
})

onMounted(() => {
  console.log('onMounted');
  document.title = 'Vie L’ven';
})

</script>
