<template>
      <div class="overlay">
            <div class="loader"></div>
      </div>
</template>

<script>
export default {
  name: 'OverlayLoader'
}
</script>

<style lang="scss">
      @import "OverlayLoader";
</style>
