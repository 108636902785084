/* eslint-disable no-undef */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import mitt from 'mitt'
import environment from '@/components/Neighborhood/util/environment.js'
import VueCookies from 'vue3-cookies'
import mapboxgl from 'mapbox-gl'
import vSelect from 'vue-select'
import OpenIndicator from '@/components/svg/OpenIndicator.vue'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'mapbox-gl/dist/mapbox-gl.css'
import 'vue-select/dist/vue-select.css'
import './assets/styles/style.scss'
import './assets/css/tailwind.css'

const app = createApp(App)
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

mapboxgl.accessToken = environment.mapBoxAccessToken

app.use(VueCookies, {
  expireTimes: '30d',
  path: '/',
  domain: ''
})

vSelect.props.components.default = () => ({
  OpenIndicator
})

app.component('v-select-component', vSelect)

app.use(VueSweetalert2);

app.config.errorHandler = function (err, vm, info) {
  console.error('Vue Error:', err, info)
}

const emitter = mitt()
app.config.globalProperties.emitter = emitter

app.use(pinia).use(router).mount('#app')
