export default {
  mapBoxAccessToken: 'pk.eyJ1IjoiYWxpemFuZGQiLCJhIjoiY2tlbzI0cWRxMjNqdzJzcGN0NXpvbDhieiJ9.UUMfcAPNPe2wHEdkYMDEeQ',
  mapbox: {
    container: 'mapContainer',
    center: [2.293506, 48.859605],
    zoom: 16.2,
    attributionControl: false,
    pitch: 62,
    bearing: -20,
    style: 'mapbox://styles/alizandd/clr20b7kt01bu01qwez6dgtpx'
  }
}
