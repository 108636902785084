import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { useCookies } from 'vue3-cookies'

const { cookies } = useCookies()

function lazyLoad (view) {
  return () => import(`../views/${view}.vue`)
}

const force = process.env.VUE_APP_MANDATORY_LOGIN
const checkAuth = (to, from, next) => {
  // console.log('force:: ', force);
  if (force == 'true') {
    const isAuthenticated = cookies.get('token')
    // console.log('to:', to, '\nfrom:', from, '\nnext', next)
    if (to.name === 'home' && isAuthenticated) {
      next('/floorplans?full_3d=true')
    }
    if (to.meta.requiresAuth && !isAuthenticated) {
      next('/')
    } else {
      next()
    }
  } else {
    if (to.name === 'home') {
      next('/floorplans?full_3d=true')
    } else {
      next()
    }
  }
}

const routes = [
  {
    path: '/',
    name: 'home',
    beforeEnter: checkAuth,
    component: HomeView
  },
  {
    path: '/Form',
    name: 'FormView',
    meta: { layout: 'default', title: 'worksheet', requiresAuth: true, has3dModel: false },
    beforeEnter: checkAuth,
    component: () => import(/* webpackChunkName: "FormView" */ '../views/FormView.vue')
  },
  {
    path: '/floorplans',
    name: 'floorplans',
    component: lazyLoad('floorplan/FloorplansPage'),
    beforeEnter: checkAuth,
    meta: { layout: 'default', title: 'Floorplan', requiresAuth: true },
    props: true
  },
  {
    path: '/developer',
    name: 'developer',
    component: lazyLoad('developer/developerPage'),
    beforeEnter: checkAuth,
    meta: { layout: 'default', title: 'developer', requiresAuth: true },
    props: true
  },
  {
    path: '/amenityPlans',
    name: 'amenityPlans',
    component: lazyLoad('amenityPlans/amenityPlans'),
    beforeEnter: checkAuth,
    meta: { layout: 'default', title: 'amenityPlans', requiresAuth: true },
    props: true
  },
  {
    path: '/marketing',
    name: 'marketingMedia',
    component: lazyLoad('marketingMedia/marketingMedia'),
    beforeEnter: checkAuth,
    meta: { layout: 'default', title: 'marketingMedia', requiresAuth: true },
    props: true
  },
  {
    path: '/favourites',
    name: 'favouritesPage',
    component: lazyLoad('favourites/favouritesPage'),
    beforeEnter: checkAuth,
    meta: { layout: 'default', title: 'favourites', requiresAuth: true },
    props: true
  },
  {
    path: '/compare',
    name: 'comparePage',
    component: lazyLoad('compare/comparePage'),
    beforeEnter: checkAuth,
    meta: { layout: 'default', title: 'compare', requiresAuth: true },
    props: true
  },
  {
    path: '/contactus',
    name: 'contactUs',
    component: lazyLoad('contactUs/contactUs'),
    beforeEnter: checkAuth,
    meta: { layout: 'default', title: 'contactUs', requiresAuth: true },
    props: true
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: lazyLoad('gallery/galleryPage'),
    beforeEnter: checkAuth,
    meta: { layout: 'default', title: 'gallery', requiresAuth: true },
    props: true
  },
  {
    path: '/neighbourhood',
    name: 'Neighbourhood-page',
    component: lazyLoad('neighbourhood/NeighbourhoodView'),
    meta: { layout: 'default', title: 'Neighbourhood', requiresAuth: true },
    props: true
  },
  {
    path: '/builderstory',
    name: 'builderstory-page',
    component: lazyLoad('builderStory/builderStoryPage'),
    meta: { layout: 'default', title: 'Neighbourhood', requiresAuth: true },
    props: true
  },
  {
    path: '/broker-package',
    name: 'broker-package-page',
    component: lazyLoad('brokerPackage/brokerPackagePage'),
    meta: { layout: 'default', title: 'Brocker Package', requiresAuth: true },
    props: true
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
