<template>
    <div class="flex flex-col justify-center items-stretch main-header z-100">
        <div class="bg-white flex w-full flex-col items-stretch pt-[1.125rem] max-md:max-w-full">
            <div class="flex items-stretch justify-between gap-5 mx-4  md:mx-9 max-md:max-w-full max-md:flex-wrap mr-4 max-md:mr-2.5">
                <div class="flex items-stretch justify-between gap-5 w-full md:w-fit">
                    <div @click="toggleSidebar()" class=" cursor-pointer menu-container border border-mainBlack-1000">
                        <div class="menu menu-1">
                            <span class="menu-item bg-mainBlack-1000"></span>
                            <span class="menu-item bg-mainBlack-1000"></span>
                            <span class="menu-item bg-mainBlack-1000"></span>
                        </div>
                        <!-- <img loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/c4aa2cfa9f4af21877307711c4e613a2e99161285af2fe82c17097dc3f077ba2?"
                        class="aspect-square object-contain object-center w-[60px] overflow-hidden shrink-0 max-w-full" /> -->
          </div>
          <img
            src="@/assets/images/logo-dark.svg"
            loading="lazy"
            class="aspect-[2.13] object-contain object-center w-[102px] overflow-hidden self-center shrink-0 max-w-full my-auto"
          />
        </div>
        <div
          class="hidden md:flex items-stretch justify-between gap-3 max-md:max-w-full max-md:flex-wrap"
        >
          <qooBtn
            type="secondary"
            icon="star2.svg"
            text="Favourites"
            @clickBtn="favouritesPage"
          ></qooBtn>
          <!-- <qooBtn
            icon="worksheet.svg"
            text="Submit Worksheet"
            @clickBtn="worksheet"
          ></qooBtn> -->
        </div>
      </div>
      <div
        class="bg-black qoo-line min-h-[1px] w-full mt-[1.0625rem] max-md:max-w-full"
      ></div>
    </div>
  </div>
  <MainMenu @closeSidebar="closeSidebar" />
</template>

<script setup>
import { ref } from 'vue'
import MainMenu from './MainMenu.vue'
import qooBtn from './common/qooBtn.vue'
import { useRouter } from 'vue-router'

const router = useRouter()
const openMenu = ref(false)

let active = true
const toggleSidebar = () => {
  try {
    setTimeout(() => {
      active = true
    }, 800)
    if (active) {
      openMenu.value = !openMenu.value
      const main1 = document.getElementsByClassName('main-header')[0]
      const line = document.getElementsByClassName('qoo-line')[0]
      main1.classList.toggle('w-full')
      line.classList.toggle('bg-black')

      const sidenav = document.getElementById('main-sidebar')
      const main = document.getElementsByClassName('qooBox-main')[0]
      const menu1 = document.getElementsByClassName('menu-1')[0]
      sidenav.classList.toggle('open')
      menu1.classList.toggle('open')

      main.classList.toggle('open-sidebar')
      active = false

      if(openMenu.value) {
        document.getElementsByTagName('html')[0].style.overflow = "hidden";
      } else {
        document.getElementsByTagName('html')[0].style.overflow = "auto";
      }

      // if(document.body.classList) {
      //   document.body.classList.toggle('overflow-hidden');
      //   document.body.classList.toggle('h-[100vh]');  
      // }
      // if(document.getElementById('app')) {
      //   document.getElementById('app').classList.toggle('overflow-hidden');
      //   document.getElementById('app').classList.toggle('h-[100vh]');
      // }
    }
  } catch (error) {
    console.log(error)
  }
}

const closeSidebar = () => {
  openMenu.value = false
}

const favouritesPage = () => {
  if(openMenu.value) {
    toggleSidebar()
  }
  router.push({ name: 'favouritesPage' })
}

const worksheet = () => {
  if(openMenu.value) {
    toggleSidebar()
  }
  router.push({ name: 'worksheet' })
}


</script>

<style lang="scss">
@import 'MainHeader.scss';

.qoo-line {
  transition: 1.2s ease all;
}

$menu-width: 24px;
$menu-height: 24px;

.menu-container {
    border-radius: 30px;
    // border: 1px solid #000;
    background: rgba(217, 217, 217, 0.00);
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu {
  width: $menu-width;
  // height: $menu-height;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  &:hover {
    cursor: pointer;
  }
}

.menu-1 {
    &.open {
        .menu-item {
            // background: #000000;
            width: 26px;
            &:first-child {
                transform: rotate(45deg);
                transform-origin: 6px 5px;
            }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
        transform-origin: 5px -2px;
      }
    }
  }
}

.menu-item {
  transition: 0.5s ease all;

  display: block;
  width: $menu-width;
  height: 2.5px;
  // margin: 2.5px 0;
  box-sizing: border-box;
  border-radius: 5px;
  background: #000000;
}
</style>
